
import { Model, Attribute, HasMany, RelationHandling } from './decorators';
import { BaseApiModel } from './base';
import { TargetVersion } from './target-version.model';
import { TargetType, TargetVersionType } from '@parashift/shared/types';

const TargetStatus = {
  draft: $localize `:@@common.draft:Draft` as 'draft',
  dev: $localize `:@@common.dev:Dev` as 'dev',
  stable: $localize `:@@common.stable:Stable` as 'stable',
};
type TargetStatus = (typeof TargetStatus)[keyof typeof TargetStatus];
export { TargetStatus };

export interface TargetPlainModel {
  id: string;
  created_at: string;
  identifier: string;
  input_arguments: string;
  input_barcodes: boolean;
  input_images: boolean;
  input_layout: boolean;
  input_pdf: boolean;
  input_source_files: boolean;
  input_text: boolean;
  status: TargetStatus;
  updated_at: string;

  target_versions: (TargetVersion['plainModel'] | Partial<TargetVersion['plainModel']>)[];
}

@Model({ type: TargetType })
export class Target extends BaseApiModel<TargetPlainModel> {
  @Attribute({ readonly: true })
  created_at: string;

  @Attribute()
  identifier: string;

  @Attribute()
  input_arguments: object;

  @Attribute()
  input_barcodes: boolean;

  @Attribute()
  input_images: boolean;

  @Attribute()
  input_layout: boolean;

  @Attribute()
  input_pdf: boolean;

  @Attribute()
  input_source_files: boolean;

  @Attribute()
  input_text: boolean;

  @Attribute()
  status: TargetStatus;

  @Attribute({ readonly: true })
  updated_at: string;

  // Includes / Relations

  @RelationHandling('destroy')
  @HasMany({ class: TargetVersionType, sidepostable: true })
  target_versions: (TargetVersion | Partial<TargetVersion>)[];

  get plainModel() {
    const model = this.toHash() as TargetPlainModel;
    model.input_arguments = this.getInputArguments();

    return model;
  }

  set plainModel(model) {
    this.customUpdates = {
      input_arguments: setInputArguments
    };

    this.setPlainModel(model);
  }

  getInputArguments() {
    if (!this.input_arguments) {
      return '';
    }
    return JSON.stringify(this.input_arguments);
  }
}

function setInputArguments(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel[key]) {
    existingModel[key] = JSON.parse(updatedPlainModel[key]);
  } else {
    existingModel[key] = null;
  }
}
